import React from 'react'
// import { Link } from "gatsby-plugin-intl"
// import { graphql } from 'gatsby'
// import Img from 'gatsby-image'
import Layout from "../components/Layout"
import SEO from "../components/Seo"
// import Button from "../components/Button"
// import Legend from "../components/Legend"
import { Grid, Row, Col } from 'react-flexbox-grid'
// import ScrollIntoView from 'react-scroll-into-view'

// import logoFull from "../assets/svg/logo-full.svg"
import logoSmallBeige from "../assets/svg/logo-small-beige.svg"
// import scrollDown from "../assets/svg/scroll-down.svg"
// import scrollTop from "../assets/svg/scroll-top.svg"
import bgEsper from "../assets/svg/bg-esperluette.svg"
// import bgEsperHome from "../assets/svg/bg-esperluette-home.svg"
// import pastImplan from "../assets/svg/pastilleJaune.svg"
// import pastInter from "../assets/svg/pastillemarron.svg"

// import SVGMap1200 from "../images/carte_1200px.svg"
// import { TooltipParis, TooltipVenise, TooltipTokyo } from "../components/MapTooltips.js"
// import { ZoneParis, ZoneVenise, ZoneTokyo } from "../components/MapZones.js"



const UnavailablePage = () => (


  <Layout clean>
    <SEO title="Mentions Légales" />
    <section className="section-wrapper" id="errordocument">
      <img className="bg-esper" src={bgEsper} alt="" />
      <Grid fluid>
        <Row className="hat">
          <Col mdOffset={1} xs={12} md={5}>
            <img className="logo-full" src={logoSmallBeige} alt="" />
            <h2>Le site est actuellement en maintenance.</h2>
            <p><strong>Merci de réessayer plus tard.</strong></p>
          </Col>
        </Row>
      </Grid>
    </section>
  </Layout>
)

export default UnavailablePage
